<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <!-- title -->
        <div class="title-box">
          <h1 class="title-main">佛山市金宏旺金属材料有限公司</h1>
          <h2 class="title-sub">询价单</h2>
        </div>

        <!-- tableData -->
        <div class="table-box">
          <PrintTable :data="detailData" />
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintTable from './print-table'
export default {
  components: {
    PrintTable
  },
  data() {
    return {
      loaded: false,
      detailData: {},
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
        // preview: true, // 是否开启预览
        // previewTitle: '打印预览', // 预览标题
      }
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'get',
        url: '/admin/gcvip/offerPurchase/print',
        params: {
          ids: this.$route.query.ids
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.detailData = this.$util.deepCopy(res.data.list)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Arial,Microsoft YaHei,SimHei,SimSun';
  overflow: hidden;
}

// title
.title-box {
  margin: 10px 0 30px 0;
  text-align: center;
}
.title-main {
  margin: 0;
  font-size: 20px;
}
.title-sub {
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
}

// table
.table-box {
  margin: 20px 0;
}
</style>
